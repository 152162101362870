<template>
  <div class="container admin-users-container">
    <div class="row justify-content-between">
      <h1 class="col-auto">Users Availability</h1>
    </div>
    <alert v-if="loading" class="light-shadow" />
    <div
      class="
        card
        light-shadow
        border-0
        theme-card
        overflow-auto
        mobile-tablet-no-card
      "
      v-if="!loading"
    >
      <div class="card-body noPrint" v-if="!loading && currentUser">
        <div class="d-flex justify-content-end flex-wrap">
          <div
            class="
              d-flex
              align-items-center
              mr-auto
              text-dark
              col-6 col-md-auto
              mb-3
              pl-0
            "
          >
            <span>
              <b v-if="!loading">
                <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
                {{ busy ? "" : users.length }}/{{ total || 0 }}</b
              >
              <i class="fas fa-spin fa-circle-notch" v-if="loading"></i>
              User{{ loading || total != 1 ? "s" : "" }}
            </span>
          </div>
          <div class="d-flex justify-content-end m-0">
            <select-control
              v-model="userType"
              :options="userTypes"
              type="horizontal"
              class="mr-3 users-types"
              @input="getFilteredUsers()"
            ></select-control>
          </div>
        </div>
        <div class="d-flex justify-content-between flex-wrap">
          <div
            class="form-group mb-0 col-12 col-md-6 col-xl-8 p-0"
            v-if="!loading"
          >
            <input
              type="search"
              class="form-control pr-5"
              @keydown.enter="SearchUserText"
              v-model.trim="searchContent"
              placeholder="Search by Email Address, First or Last Name"
            />
            <span>
              <i
                class="fa fa-search search-icon text-black"
                @click="SearchUserText"
              ></i>
              <i
                v-if="searchContent"
                class="fas fa-times cancel-icon"
                @click="(searchContent = ''), SearchUserText()"
              ></i>
            </span>
          </div>
          <div
            class="
              d-flex
              flex-row
              align-items-center
              col-12 col-md-5 col-xl-3
              mt-3 mt-md-0
              p-0
              form-group
              multi-select-classes
              mb-0
            "
          >
            <label class="col-form-label col-auto py-0 pl-0 m-0 mb-md-0">
              Class:
            </label>
            <multiselect
              v-model="selectedClasses"
              :options="classifications"
              v-if="!loading"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :searchable="true"
              placeholder="All Classes"
              Value="value"
              label="label"
              track-by="value"
              class="combobox col p-0"
              :preselect-first="false"
              @input="isClassesMultiSelectDirty = true"
              @open="isClassesMultiSelectOpen = true"
              @close="
                (isClassesMultiSelectOpen = false),
                  isClassesMultiSelectDirty && getFilteredUsers()
              "
              @remove="!isClassesMultiSelectOpen && getFilteredUsers()"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single text-primary"
                  v-if="values.length > 1 &amp;&amp; !isOpen"
                >
                  {{ values.length }} Classes Selected
                </span>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div
        class="card p-0 extra-semi-transparent-card overflow-auto"
        v-if="users.length && !loading"
      >
        <table
          class="table responsive-mobile-tablet-table"
          :class="users.length ? 'mb-0' : ''"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Profile</th>
              <th>Name</th>
              <th>Email</th>
              <th class="text-center">Availability</th>
              <th style="min-width: 125px">Updated On</th>
              <th class="text-center">Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="u in users" :key="u.id">
              <td><b class="mobile-tablet-only mr-2">ID:</b>{{ u.id }}</td>
              <td>
                <div
                  class="
                    d-inline-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b class="mobile-tablet-only mr-2">Profile:</b>
                  <div class="position-relative">
                    <img
                      class="user-avatar-img rounded-circle"
                      :src="u.profile_picture || profileImagePlaceholder"
                    />
                    <div
                      class="user-status"
                      :class="{
                        online: isOnline(u.id),
                        offline: !isOnline(u.id),
                      }"
                    />
                  </div>
                </div>
              </td>
              <td><b class="mobile-tablet-only mr-2">Name:</b>{{ u.name }}</td>
              <td class="break-word">
                <b class="mobile-tablet-only mr-2">Email:</b
                ><a :href="`mailto:${u.email}`">{{ u.email }}</a>
              </td>
              <td class="w-32">
                <b class="mobile-tablet-only mr-2">Availability:</b>

                <div
                  class="card extra-semi-transparent-card border py-3 px-3 mb-0"
                  style="font-size: 14px"
                >
                  <h3 class="mb-2" style="background: #f3f1f1; padding: 8px">
                    Weekly Hours
                  </h3>
                  <div
                    class="d-flex mb-3 justify-content-between px-3"
                    v-for="(day, key) in weekDays"
                    :key="key"
                  >
                    <div class="font-weight-bold mr-3">
                      {{ day.substring(0, 3).toUpperCase() }}:
                    </div>
                    <div class="font-weight-bold">
                      <div
                        v-if="!(u.weekDays[day] || []).length"
                        class="text-danger"
                      >
                        Unavailable
                      </div>
                      <div
                        v-for="(interval, index) in u.weekDays[day]"
                        class="text-blue"
                        :key="index"
                      >
                        {{ interval.from }} - {{ interval.to }}
                      </div>
                    </div>
                  </div>
                  <div v-if="u.customDates.length">
                    <h3 class="mb-4" style="background: #f3f1f1; padding: 8px">
                      Custom Dates
                    </h3>
                    <div
                      class="d-flex mb-3 justify-content-between px-3"
                      v-for="(customDate, key) in u.customDates"
                      :key="key"
                    >
                      <div class="font-weight-bold mr-3">
                        {{ customDate.dateValue }}:
                      </div>
                      <div class="font-weight-bold">
                        <div
                          v-if="!(customDate.intervals || []).length"
                          class="text-danger"
                        >
                          Unavailable
                        </div>
                        <div
                          v-for="(interval, index) in customDate.intervals"
                          :key="index"
                          class="text-blue"
                        >
                          {{ interval.from }} - {{ interval.to }}
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>{{ formattedDate((u.availability[0] || {}).updated_at) }}</td>
              <td class="text-center">
                <b class="mobile-tablet-only mr-2">Type:</b
                >{{ USER_TYPE[u.roleId] }}
              </td>
            </tr>
          </tbody>
        </table>
        <mugen-scroll :handler="loadMore" :should-handle="!busy && !loading">
          <alert
            v-if="busy"
            class="
              text-center
              p-2
              mb-0
              mobile-tablet-loadmore mobile-tablet-shadow-card
            "
            ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
            users...</alert
          >
        </mugen-scroll>
      </div>
      <div
        class="card-body pb-0"
        v-if="currentUser && !users.length && !loading"
      >
        <alert> No results found </alert>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import MugenScroll from "vue-mugen-scroll";
import * as dayjs from "dayjs";

export default {
  name: "Users-availability",
  components: { MugenScroll, Multiselect },
  data() {
    return {
      busy: false,
      limit: 50,
      searchContent: "",
      latestSearch: "",
      selectedClasses: [],
      userTypes: [
        { value: -1, label: "All" },
        { value: 1, label: "Admins" },
        { value: 5, label: "Managers" },
        { value: 2, label: "Providers" },
        { value: 3, label: "Clients" },
      ],
      weekDays: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "sunday",
      ],
      USER_TYPE: ["N/A", "Admin", "Provider", "Client", "Referer", "Manager"],
      userType: -1,
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      isClassesMultiSelectOpen: false,
      isClassesMultiSelectDirty: false,
    };
  },
  async mounted() {
    await this.getClassifications();
    this.getFilteredUsers();
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.user,
      users: (state) => state.availability.usersAvailability.pagination.data,
      total: (state) => state.availability.usersAvailability.pagination.total,
      current: (state) =>
        state.availability.usersAvailability.pagination.current,
      loading: (state) => state.availability.usersAvailability.loading,
      onlineUsers: (state) => state.auth.onlineUsers,
      classifications: (state) =>
        state.classifications.data.map((item) => {
          return { label: item.subject, value: item.id };
        }),
    }),
  },
  methods: {
    ...mapActions({
      getUsersAvailability: "availability/getUsersAvailability",
      getClassifications: "classifications/get",
    }),
    getFilteredUsers: function (page) {
      const payload = {
        limit: this.limit,
        roleId: this.userType,
        status: "ACTIVE",
      };
      if (this.selectedClasses.length > 0) {
        payload.classIds = `[${this.selectedClasses
          .map((item) => item.value)
          .join(",")}]`;
      }
      if (page) {
        payload.page = page;
      }
      if (this.latestSearch) {
        payload.username = this.latestSearch;
      }
      this.getUsersAvailability(payload).then(() => {
        this.busy = false;
      });
    },
    loadMore() {
      if (this.users.length < this.total) {
        this.busy = true;
        this.getFilteredUsers(this.current + 1);
      }
    },
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("YYYY-MM-DD");
      } else return "";
    },
    SearchUserText() {
      if (this.latestSearch == this.searchContent) {
        return;
      }
      this.latestSearch = this.searchContent;
      this.getFilteredUsers();
    },
    isOnline: function (id) {
      return (this.onlineUsers || []).includes(id);
    },
  },
};
</script>
<style scoped lang="scss">
.table-continer {
  overflow-x: auto;
}

.table tbody td {
  vertical-align: top;
}
.admin-users-container {
  @media (max-width: 1024px) {
    .responsive-mobile-tablet-table {
      margin-top: 4px;
    }
    .card-body {
      background-color: white;
    }
    .page-header {
      display: block !important;
    }
  }
}

.cancel-icon,
.search-icon {
  z-index: 99;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 15px;
}
.form-group {
  position: relative;
}

.user-avatar-img {
  width: 45px;
  min-width: 45px;
  height: 45px;
}
.user-status {
  position: absolute;
  right: -1px;
  bottom: 0px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  border: 1px solid #edf2f9;
  &.online {
    background-color: #4caf50;
  }
  &.offline {
    background-color: #9ca6af;
  }
  @media (max-width: 768px) {
    right: 0px;
  }
}
</style>
<style lang="scss">
.users-types {
  .form-group {
    margin-bottom: 0;
  }
  .col {
    padding-right: 0;
  }
  .form-control {
    height: 28px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.multi-select-classes {
  .multiselect--disabled .multiselect__select {
    background-color: transparent;
  }
  .multiselect__tags {
    height: calc(1.5em + 1rem + 2px);
    border: 1px solid #d2ddec;
  }
  .multiselect__select:before {
    border-color: #12263f transparent transparent;
  }
  .multiselect--disabled {
    background-color: transparent;
  }
  .combobox {
    width: 66.67% !important;
  }
}
</style>
